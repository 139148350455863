define(['app', '$window', 'wishlistProduct', 'componentHelper'], (app, $window, wishlistProduct, componentHelper) => {

  const wishlistVariations = () => {
    const component = {};
    const DATA_REMOVE_ITEM = 'data-item-remove-enabled';
    const eventCategoryTracking = 'Wishlist Engagement';

    const _config = {
      productIds: {
        masterProductId: 'data-master-id',
        childProductId: 'data-child-id',
      },
      buttons: {
        cancelButton: '.wishlistVariations_cancelButton',
        updateButton: '.wishlistVariations_updateButton',
        basketButton: '.wishlistVariations_addToBasketButton',
      },
      highlights: {
        notSelected: '.wishlistVariations_notSelected',
      },
      subscribeChannels: {
        variations: 'productVariations/variationData',
      },
    };

    const _init = (element, userDriven) => {
      component.element = element;
      component.parent = component.element.parentElement.parentElement;
      component.variations = component.element.querySelectorAll('.wishlistVariation');
      component.masterProductId = component.element.getAttribute(component.config.productIds.masterProductId);
      component.childProductId = component.element.getAttribute(component.config.productIds.childProductId);
      component.itemRemoveEnabled = component.element.getAttribute(DATA_REMOVE_ITEM);
      component.basketButton = component.parent.querySelector('.wishlistProduct_productAddToBasket');
      component.actionButtons = component.element.querySelector('.wishlistVariations_actionButtons');
      component.cancelSelectionButton = component.element.querySelector('.wishlistVariations_cancelSelection');
      component.updateSelectionButton = component.element.querySelector('.wishlistVariations_updateSelection');

      if (component.parent.getAttribute(component.config.productIds.childProductId) !== '') {
        component.currentWishlistedId = component.parent.getAttribute(component.config.productIds.childProductId);
      } else {
        component.currentWishlistedId = component.parent.getAttribute(component.config.productIds.masterProductId);
      }

      if (userDriven) {
        component.displayActionButtons();
      } else {
        if (component.basketButton !== null && component.basketButton.classList.contains('wishlistVariations_actionButtons-hidden')) {
          component.basketButton.classList.remove('wishlistVariations_actionButtons-hidden');
        }
      }

      component.disableVariation();
      component.addEventListeners();

      return component;
    };

    const _addEventListeners = () => {
      for (var i = 0, l = component.variations.length; i < l; i++) {
        component.variations[i].addEventListener('change', component.optionChanged);
      }

      component.cancelSelectionButton.addEventListener('click', component.cancelSelectionClicked);
      component.updateSelectionButton.addEventListener('click', component.updateSelectionClicked);
    };

    const _optionChanged = (e) => {
      if(e.target){
        component.variationIDToReturnFocusTo = e.target.getAttribute('variation-id');
      }

      app.ajax.post({
        url: '/' + component.masterProductId + '.wishlistvariations',
        send: JSON.stringify(component.constructPostData()),
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: component.variationsSuccessHandler,
        error: component.variationsErrorHandler,
      });
    };

    const _constructPostData = () => {
      const postData = {
        selected: 0,
      };

      for (let i = 0, l = component.variations.length; i < l; i++) {
        const input = component.variations[i];
        postData['variation' + (i + 1)] = input.getAttribute('variation-id');
        postData['option' + (i + 1)] = input.value;
        if (input.value) postData.selected++;
      }

      return postData;
    };

    const _variationsSuccessHandler = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      const newElement = parent.querySelector('[data-component=wishlistVariations]');
      const variationIDToReturnFocusTo = component.variationIDToReturnFocusTo;
      component.init(newElement, true);
      const elementToReturnFocusTo = component.element.querySelector(`select[variation-id="${variationIDToReturnFocusTo}"]`);
      elementToReturnFocusTo && elementToReturnFocusTo.focus();
    };

    const _variationsErrorHandler = () => {
      console.error(
        'ERROR: Could not retrieve variation data for product: ' + component.childProductId);
    };

    const _displayActionButtons = () => {
      component.actionButtons.classList.toggle('wishlistVariations_actionButtons-hidden');

      if (component.basketButton !== null && !component.basketButton.classList.contains('wishlistVariations_actionButtons-hidden')) {
        component.basketButton.classList.add('wishlistVariations_actionButtons-hidden');
      }
    };

    const _removeProduct = (productId) => {
      return new Promise((res, rej) => app.ajax.call({
        url: '/wishlist/removeFrom.collections?fromWishlist=true&productID=' + productId,
        send: [productId],
        type: 'DELETE',
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: res,
        error: rej,
      }));
    };

    const _addNewProduct = (productId) => {
      let mapCategoriesToSkus = 'false';
      let type = 'wishlist';
      let endpoint = `/${type}/${mapCategoriesToSkus}/addTo.collections`;

      return new Promise((res, rej) => app.ajax.post({
        url: endpoint + '?fromWishlist=true&productID=' + productId,
        send: [productId],
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: component.getWishlistedProduct,
        error: rej,
      }));
    };

    const _disableVariation = () => {
      if (component.variations !== null || component.variations !== undefined) {
        if (component.variations[0].selectedIndex === 0) {
          for (let i = 1, l = component.variations.length; i < l; i++) {
            const variation = component.variations[i];
            variation.setAttribute('disabled', 'disabled');
            variation.classList.add('disabled');
          }
        }
      }
    };

    const _cancelSelectionClicked = () => {
      app.ajax.get({
        url: '/' + component.currentWishlistedId + '.wishlistvariations',
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: component.revertSelection,
        error: component.variationsErrorHandler,
      });
    };

    const _updateSelectionClicked = () => {
      component.removeProduct(component.currentWishlistedId)
        .then(component.addNewProduct(component.childProductId));

      app.publish('tracking/record', eventCategoryTracking, 'updated wishlist', component.currentWishlistedId + ' to ' + component.childProductId);

    };

    const _revertSelection = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      const newElement = parent.querySelector('[data-component=wishlistVariations]');
      component.init(newElement);
    };

    const _getWishlistedProduct = () => {
      app.ajax.get({
        url: '/' + component.childProductId + '.wishlistproduct',
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: component.updateWishlistedProduct,
        error: component.variationsErrorHandler,
      });
    };

    const _updateWishlistedProduct = (response) => {
      const parent = component.parent.parentNode;
      parent.innerHTML = response;
      const newWishlistedProduct = parent.querySelector('[data-component=wishlistProduct]');
      componentHelper.reloadAllComponents(newWishlistedProduct);
    };

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.optionChanged = _optionChanged;
    component.constructPostData = _constructPostData;
    component.variationsSuccessHandler = _variationsSuccessHandler;
    component.variationsErrorHandler = _variationsErrorHandler;
    component.displayActionButtons = _displayActionButtons;
    component.disableVariation = _disableVariation;
    component.removeProduct = _removeProduct;
    component.addNewProduct = _addNewProduct;
    component.cancelSelectionClicked = _cancelSelectionClicked;
    component.updateSelectionClicked = _updateSelectionClicked;
    component.revertSelection = _revertSelection;
    component.getWishlistedProduct = _getWishlistedProduct;
    component.updateWishlistedProduct = _updateWishlistedProduct;

    return component;
  };

  return wishlistVariations;
});
